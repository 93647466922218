export default {
  name: '',
  components: {},
  props: {},

  data() {
    return {};
  },

  computed: {},
  watch: {},

  created() {},

  mounted() {},

  methods: {}
};